import { useMemo } from "react";
import usePropertyLocations from "../../../hooks/use-property-locations";

function useNearbyLocationsTemplate(
  locationSlug: string,
): PropertyLocation[] {
  const { propertyLocations } = usePropertyLocations();

  const template = useMemo(
    () => {
      const locationSlugs = NEARBY_LOCATIONS[locationSlug]  ?? [];

      const compiled = locationSlugs.reduce(
        (compiled: PropertyLocation[], locationSlug: string) => {
          const found = propertyLocations.find(each => each.slug === locationSlug);

          if (found) {
            compiled.push(found);
          }

          return compiled;
        },
        [] as PropertyLocation[],
      );

      return compiled;
    },
    [propertyLocations, locationSlug],
  )
  return template;
}

export default useNearbyLocationsTemplate;

const NEARBY_LOCATIONS : { [key: string]: string[] }= {
  "india-karjat": [
    "india-lonavala",
    "india-alibaug",
    "india-mahabaleshwar",
  ],
  "india-lonavala": [
    "india-karjat",
    "india-alibaug",
    "india-mahabaleshwar",
   ],
  // "india-bhimtal": [
  //   "india-mussoorie",
  //   "india-srinagar",
  // ],
  "india-coonoor": [
    "india-goa",
  ],
  "india-mussoorie": [
    // "india-bhimtal",
    "india-goa",
  ],
  "india-alibaug": [
    "india-lonavala",
    "india-karjat",
    "india-mahabaleshwar",
  ],
  "india-srinagar": [
    "india-kasauli",
    "india-mussoorie",
    // "india-bhimtal",
  ],
  "india-kasauli": [
    "india-mussoorie",
    // "india-bhimtal",
    "india-srinagar",
  ],
  "india-mahabaleshwar": [
    "india-karjat",
    "india-lonavala",
    "india-alibaug",
  ],
}
import React, { ReactNode, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { Page } from "../../../../typings/page";
import { DOMESTIC_PROPERTY_LOCATIONS, INTERNATIONAL_PROPERTY_LOCATIONS } from "../../core/locations";
import useClx from "../../hooks/use-clx";
import useContactUsPhoneNo from "../../hooks/use-contact-us-phone-no";
import useCountryCode from "../../hooks/use-country-code";
import { usePageValue } from "../../hooks/use-page";
import { useQueryParamValue } from "../../hooks/use-query-param";
import useSendRequest from "../../hooks/use-send-request";
import { Track } from "../AnalyticsManager";
import fwclxs from "../../styles/full-width.module.css";
import clxs from "./footer.module.css";
import FACEBOOK_ICON from "../../assets/logo/facebook-logo.svg";
import INSTAGRAM_ICON from "../../assets/logo/instagram-logo.svg";
import LOHONO_LOGO_FOOTER from "../../assets/logo/lohono-logo-footer.svg";
import TWITTER_ICON from "../../assets/logo/twitter-logo.svg";
import PHONE_ICON from "../../assets/icons/phone.svg";
import LINKEDIN_ICON from "../../assets/logo/linkedIn-logo.svg";
import YOUTUBE_ICON from "../../assets/logo/youtube-logo.svg";

interface FooterProps {
  pages: Page[];
}

function Footer(props: FooterProps) {
  const { showSendRequestModal } = useSendRequest(),
    { page } = usePageValue(),
    { pathname } = useLocation(),
    { countryCode } = useCountryCode(),
    { pages } = props,
    isEmbedded = useQueryParamValue("boolean", "embedded", false),
    hideFooter = useMemo(
      () => {
        return (
          isEmbedded
          || HIDE_IN_PAGE.has(page)
          || HIDE_IN_PAGE.has(pathname)
        );
      },
      [pathname, page, isEmbedded],
    ),
    ccx = useClx(fwclxs.container, fwclxs.containerPadding, clxs.container),
    lccx = useClx(fwclxs.container, fwclxs.containerPadding, clxs.locationContainer),
    isEventsPage = pathname?.includes("experiences-and-events"),
    { friendly: contactUsPhoneNo, tel: contactUsTel } = useContactUsPhoneNo(false, isEventsPage),
    handleEnquireNow = () => {
      if(isEventsPage){
        return showSendRequestModal( {interest: "events_and_experience", location: "india-goa" } );
      }
      return showSendRequestModal( {interest: "rental", location: "india-goa" } );
    };

  const showInternational = useMemo(
    () => {
      return countryCode === "IN";
    },
    [countryCode],
  );

  const year = new Date().getFullYear();

  return (
    <>
      <footer
        data-hidden={hideFooter}
        suppressHydrationWarning={true}
        className={clxs.hideFooter}
      >
      <div className={lccx}>
        <div className={clxs.propertyTitle}>
          Domestic Properties
        </div>
        <div className={clxs.locationLinks}>
          {DOMESTIC_PROPERTY_LOCATIONS.map(
            (each, key) => {
              return (
                <Track
                  key={each.path}
                  event="footer_item_clicked"
                  payload={{
                    ...each.meta,
                    index: key,
                    type: "location",
                    title: each.header,
                    header: each.header,
                    href: each.path,
                  }}
                >
                  <a
                    title={each.header}
                    href={each.path}
                    className={clxs.location}
                  >
                    {each.header}
                  </a>
                </Track>
              );
            },
          )}
        </div>
        <div className={clxs.propertyTitle}>
          International Properties
        </div>
        {showInternational &&
          <div className={clxs.locationLinks}>
            {INTERNATIONAL_PROPERTY_LOCATIONS.map(
              (each, key) => {
                return (
                  <Track
                    key={each.path}
                    event="footer_item_clicked"
                    payload={{
                      ...each.meta,
                      index: key,
                      type: "location",
                      title: each.header,
                      header: each.header,
                      href: each.path,
                    }}
                  >
                    <a
                      title={each.header}
                      href={each.path}
                      className={clxs.location}
                    >
                      {each.header}
                    </a>
                  </Track>
                );
              },
            )}
          </div>
        }
      </div>
      <div className={ccx}>
      <div className={clxs.brand}>
          <img
            src={LOHONO_LOGO_FOOTER}
            alt="Lohono"
            className={clxs.logo}
            loading="lazy"
          />
          <div>
            Lohono Stays specializes in creating and curating luxury holiday homes for rent all over the world. Tied in with our signature hospitality, the endeavour is to offer the most luxurious and comfortable holiday experiences to our guests with our private villas.
          </div>
        </div>
        <div className={clxs.quickLinks}>
          <div className={clxs.title}>
            Quick Links
          </div>
          {pages.reduce(
            (nodes, page) => {
              const {
                footer,
                new_tab,
                path,
                title,
                meta,
              } = page;

              if (!footer) {
                return nodes;
              }
              return [
                ...nodes, (
                  <Track
                    key={path}
                    event="footer_item_clicked"
                    payload={{
                      ...meta,
                      type: "page",
                      title: footer,
                      header: footer,
                      href: path,
                    }}
                  >
                    <a
                      key={path}
                      href={path}
                      target={new_tab ? "_blank" : "_self"}
                      className={clxs.quickLink}
                      rel={new_tab ? "noopener noreferrer" : undefined}
                      title={title}
                    >
                      {footer}
                    </a>
                  </Track>
                ),
              ];
            },
            [] as ReactNode[],
          )}
        </div>
        <div className={clxs.address}>
          <div className={clxs.title}>
            Get In Touch
          </div>
          <Track
            event="footer_item_clicked"
            payload={{ type: "address" }}
          >
            <a
              href="https://goo.gl/maps/f6L1cUPe6tiQoZdZA"
              target="_blank"
              rel="noreferrer"
              className={clxs.addressContent}
            >
              42A, Impression House, G D Ambekar Marg, Kohinoor Mill, Wadala, Mumbai, Maharashtra – 400031
            </a>
          </Track>
          <div className={clxs.callUs}>
            <a
              href={contactUsTel}
              className={clxs.phoneNo}
              title="Call us"
            >
              <img
                src={PHONE_ICON}
                alt="call"
                className={clxs.phoneIcon}
              />
              {contactUsPhoneNo}
            </a>
            <Track event="footer_send_request_clicked">
              <button
                className={clxs.enquireNow}
                onClick={handleEnquireNow}
              >
                Enquire Now
              </button>
            </Track>
          </div>
          <div className={clxs.followUs}>
            <div className={clxs.title}>
              Follow us on -
            </div>
            <Track
              event="footer_item_clicked"
              payload={{ type: "facebook" }}
            >
              <a
                href="https://www.facebook.com/lohonostays/"
                className="icon"
                target="_blank"
                rel="noopener noreferrer"
                title="Follow on Facebook"
              >
                <img
                  src={FACEBOOK_ICON}
                  alt="facebook"
                  className={clxs.socialIcon}
                />
              </a>
            </Track>
            <Track
              event="footer_item_clicked"
              payload={{ type: "twitter" }}
            >
              <a
                href="https://twitter.com/LohonoStays"
                className="icon"
                target="_blank"
                rel="noopener noreferrer"
                title="Follow on Twitter"
              >
                <img
                  src={TWITTER_ICON}
                  alt="twitter"
                  className={clxs.socialIcon}
                />
              </a>
            </Track>
            <Track
              event="footer_item_clicked"
              payload={{ type: "instagram" }}
            >
              <a
                href="https://www.instagram.com/lohono_stays/?utm_source=lohono_website&utm_medium=web_footer&utm_campaign=insta_lohonoweb"
                className="icon"
                target="_blank"
                rel="noopener noreferrer"
                title="Follow on Instagram"
              >
                <img
                  src={INSTAGRAM_ICON}
                  alt="instagram"
                  className={clxs.socialIcon}
                />
              </a>
            </Track>
            <Track
              event="footer_item_clicked"
              payload={{ type: "linkedIn" }}
            >
              <a
                href="https://www.linkedin.com/company/lohono-stays/"
                className="icon"
                target="_blank"
                rel="noopener noreferrer"
                title="Follow on LinkedIn"
              >
                <img
                  src={LINKEDIN_ICON}
                  alt="LinkedIn"
                  className={clxs.socialIcon}
                />
              </a>
            </Track>
            <Track
              event="footer_item_clicked"
              payload={{ type: "youtube" }}
            >
              <a
                href="https://www.youtube.com/@lohonostays"
                className="icon"
                target="_blank"
                rel="noopener noreferrer"
                title="Follow on Youtube"
              >
                <img
                  src={YOUTUBE_ICON}
                  alt="youtube"
                  className={clxs.socialIcon}
                />
              </a>
            </Track>
          </div>
        </div>
        <div className={clxs.declaration}>
          <div className={clxs.tnc}>
            <Track
              event="footer_item_clicked"
              payload={{ type: "tnc" }}
            >
              <a
                href="/terms-and-conditions"
                target="_blank"
                rel="noopener noreferrer"
                title="Lohono - Terms and conditions"
              >
                Terms and conditions
              </a>
            </Track>
            <Track
              event="footer_item_clicked"
              payload={{ type: "privacy" }}
            >
              <a
                href="/privacy-policy"
                className="terms-condition"
                target="_blank"
                rel="noopener noreferrer"
                title="Lohono - Privacy policy"
              >
                Privacy policy
              </a>
            </Track>
          </div>
          <div className={clxs.copyright}>
          © {year} Isprava Vesta Pvt. Ltd. All rights reserved
          </div>
        </div>
      </div>
      </footer>
    </>
  );
}

export default Footer;

const HIDE_IN_PAGE = new Set<string>([
  "LB_ABOUT",
  "FEEDBACK",
  "SEND_REQUEST",
  "/villas/india/karjat/casa-riviere",
  "LOCATIONS",
  "DELETE_ACCOUNT_VERIFY_OTP",
  "DELETE_ACCOUNT",
  "DELETE_ACCOUNT_SUCCESS",
  "PROFILE",
  "FITNESS_RETREAT",
]);
